import CampaignIcon from "@mui/icons-material/Campaign";
import {
  Avatar,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Paper,
} from "@mui/material";
import React, { memo, useState } from "react";
import ApiFetch from "../../components/ApiFetch";
import { API_MSGS } from "../../context/ApiEndPoints";
import useAppContext from "../../hooks/useAppContext";
import useComponentContext from "../../hooks/useComponentContext";
import useFetchList from "../../hooks/useFetchList";
import { stringAvatar } from "../../scripts/Scripts";
import MsgDetails from "./MsgDetails";

function MsgsList() {
  const { user } = useAppContext();
  const { setNotify } = useComponentContext();

  const [selectedRecord, setSelectedRecord] = useState();
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const [isLoading, setIsLoading] = React.useState(false);

  const { records, isFetching, fetchError } = useFetchList(
    `${API_MSGS}/Distinct`
  );

  const handleListClick = async (phoneNumber, i) => {
    if (phoneNumber) {
      setSelectedIndex(i);
      setIsLoading(true);

      // GET request using fetch
      const requestOptions = {
        headers: {
          Authorization: "Bearer " + user.token,
          "Content-Type": "application/json",
        },
      };

      const results = await ApiFetch(
        `${API_MSGS}/Phone?PhoneNumber=${encodeURIComponent(phoneNumber)}`,
        requestOptions,
        setNotify
      );

      setSelectedRecord(results);
      setIsLoading(false);
    }
  };

  return (
    <>
      {fetchError ||
        (isFetching && (
          <CircularProgress size={20} color="inherit" sx={{ m: 2 }} />
        ))}
      {records && (
        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <Paper square>
              <List
                subheader={
                  records?.length === 0 && (
                    <ListSubheader>No Record Found!</ListSubheader>
                  )
                }
              >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <CampaignIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Conversations" />
                </ListItem>
              </List>
              <Divider />
              <List disablePadding sx={{ height: "77vh", overflow: "auto" }}>
                {records.map((item, i) => (
                  <ListItem
                    key={i}
                    sx={{ borderBottom: 1, borderColor: "divider" }}
                    disablePadding
                  >
                    <ListItemButton
                      selected={selectedIndex === i}
                      onClick={(e) => handleListClick(item.customerNumber, i)}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <Avatar {...stringAvatar(item.customerNumber)} />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={item.customerNumber} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={9}>
            <MsgDetails
              selectedRecord={selectedRecord}
              setSelectedRecord={setSelectedRecord}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default memo(MsgsList);

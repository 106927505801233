import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./2wrap-fonts.css";
import Layout from "./components/Layout";
import { AppProvider } from "./context/AppContext";
import BlogDetails from "./pages/Blogs/BlogDetails";
import Colors from "./pages/Colors/Colors";
import Configs from "./pages/Configs";
import Customers from "./pages/Customers/Customers";
import UploadCustomer from "./pages/Customers/UploadCustomer";
import Dashboard from "./pages/Dashboard";
import EmailTemplates from "./pages/EmailTemplates/EmailTemplates";
import HelloCustomer from "./pages/HelloCustomer";
import InstallScheduler from "./pages/Install/InstallScheduler";
import JobDetails from "./pages/Jobs/JobDetails";
import Jobs from "./pages/Jobs/Jobs";
import Login from "./pages/Login";
import Msgs from "./pages/Msgs/Msgs";
import NotFound from "./pages/NotFound";
import EditAdvertising from "./pages/Quotes/EditAdvertising";
import EditAeronautics from "./pages/Quotes/EditAeronautics";
import EditArchitectural from "./pages/Quotes/EditArchitectural";
import EditAutomotive from "./pages/Quotes/EditAutomotive";
import EditNautical from "./pages/Quotes/EditNautical";
import EditSpecialties from "./pages/Quotes/EditSpecialties";
import QuoteAdd from "./pages/Quotes/QuoteAdd";
import QuoteApprove from "./pages/Quotes/QuoteApprove";
import RestPassword from "./pages/RestPassword";
import UserAccount from "./pages/Users/UserAccount";
import UserProfile from "./pages/Users/UserProfile";
import Users from "./pages/Users/Users";

const theme = createTheme({
  palette: {
    // mode: "dark",
    primary: {
      main: "#121212",
    },
    custom: {
      light: "#ffa726",
      main: "#f57c00",
      dark: "#ef6c00",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    background: {
      default: "#fafafa",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <AppProvider>
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="reset-password" element={<RestPassword />} />
            <Route path="hello/:alias" element={<HelloCustomer />} />
            <Route path="/" element={<Layout />}>
              <Route path="approve-quote/:quoteId" element={<QuoteApprove />} />
              <Route index path="/dashboard" element={<Dashboard />} />
              <Route path="add-quote/:quoteId?" element={<QuoteAdd />} />
              <Route
                path="edit-automotive/:quoteId"
                element={<EditAutomotive />}
              />
              <Route
                path="edit-advertising/:quoteId"
                element={<EditAdvertising />}
              />
              <Route
                path="edit-architectural/:quoteId"
                element={<EditArchitectural />}
              />
              <Route path="edit-nautical/:quoteId" element={<EditNautical />} />
              <Route
                path="edit-aeronautics/:quoteId"
                element={<EditAeronautics />}
              />
              <Route
                path="edit-specialties/:quoteId"
                element={<EditSpecialties />}
              />
              <Route path="jobs" element={<Jobs />} />
              <Route path="job-details/:quoteId" element={<JobDetails />} />
              <Route path="customers" element={<Customers />} />
              <Route path="wrap-colors" element={<Colors />} />
              <Route path="scheduler" element={<InstallScheduler />} />
              <Route path="email-templates" element={<EmailTemplates />} />
              <Route path="users" element={<Users />} />
              <Route path="profile" element={<UserProfile />} />
              <Route path="account" element={<UserAccount />} />
              <Route path="upload-customer" element={<UploadCustomer />} />
              <Route path="blog-details/:blogId" element={<BlogDetails />} />
              <Route path="msgs" element={<Msgs />} />
              <Route path="configs" element={<Configs />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </AppProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;

import ChatIcon from "@mui/icons-material/Chat";
import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Divider,
  ListSubheader,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { memo, useEffect, useRef, useState } from "react";
import ApiFetch from "../../components/ApiFetch.js";
import ButtonControl from "../../components/controls/ButtonControl.js";
import InputControl from "../../components/controls/InputControl.js";
import { API_MSGS } from "../../context/ApiEndPoints";
import useAppContext from "../../hooks/useAppContext.js";
import { stringAvatar, timeStamp } from "../../scripts/Scripts.js";

function MsgDetails({ selectedRecord, setSelectedRecord, isLoading }) {
  const { user } = useAppContext();

  const containerRef = useRef();
  const [isSaving, setIsSaving] = useState(false);

  const [msgObj, setMsgObj] = useState({
    userNumber: "+14243468979",
    customerNumber: "+13107750717",
    numSegments: 1,
    msgBody: "",
  });

  let val;
  const dateDivider = (nv) => {
    if (nv !== val) {
      val = nv;
      return (
        <Divider light sx={{ my: 1 }}>
          <Chip variant="outlined" label={val} />
        </Divider>
      );
    }
  };

  useEffect(() => {
    if (containerRef && containerRef.current) {
      const { scrollHeight, clientHeight } = containerRef.current;
      containerRef.current.scrollTo({
        left: 0,
        top: scrollHeight - clientHeight,
        behavior: "smooth",
      });
    }

    return () => {};
  }, [selectedRecord]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);

    // POST request using fetch
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + user.token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(msgObj),
    };
    const results = await ApiFetch(API_MSGS, requestOptions);

    if (results) {
      setSelectedRecord([...selectedRecord, results]);
      setMsgObj({ ...msgObj, msgBody: "" });
    }
    setIsSaving(false);
  };

  return (
    <>
      {selectedRecord && (
        <>
          <Box
            ref={containerRef}
            boxShadow={1}
            sx={{
              p: 2,
              border: 1,
              borderColor: "divider",
              borderRadius: 1,
              height: "75vh",
              overflow: "auto",
            }}
          >
            <ListSubheader>
              {isLoading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                `${selectedRecord.length} record(s)`
              )}
            </ListSubheader>

            {selectedRecord.map((e, i) => (
              <Box key={i}>
                {dateDivider(
                  dayjs(e.createdDate).calendar(null, {
                    sameDay: "[Today]",
                    lastDay: "[Yesterday]",
                    lastWeek: "dddd",
                    sameElse: "DD/MM/YYYY",
                  })
                )}
                <Stack
                  alignItems="flex-start"
                  direction={e.isRecieved ? "row-reverse" : "row"}
                  sx={{ pb: 1 }}
                >
                  <Box
                    component={Paper}
                    sx={{
                      p: 1,
                      bgcolor: e.isRecieved && "divider",
                    }}
                  >
                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Avatar
                        {...stringAvatar(
                          e.isRecieved ? e.customerNumber : e.userNumber
                        )}
                      />
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        align={e.isRecieved ? "right" : "left"}
                      >
                        {e.isRecieved ? e.customerNumber : e.userNumber}
                        <br />
                        {timeStamp(e.createdDate)}
                      </Typography>
                    </Stack>
                    <Divider light sx={{ my: 0.5 }} />
                    <Typography
                      variant="body2"
                      sx={{
                        whiteSpace: "pre-line",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {e.msgBody}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            ))}
          </Box>
          <Paper sx={{ p: 2 }}>
            <form onSubmit={handleSubmit}>
              <Stack direction="row">
                <InputControl
                  label="Type a message"
                  name="comment"
                  value={msgObj.msgBody}
                  onChange={(e) =>
                    setMsgObj({ ...msgObj, msgBody: e.target.value })
                  }
                  multiline
                  required
                  sx={{ m: 0 }}
                />
                <ButtonControl
                  type="submit"
                  endIcon={<ChatIcon />}
                  isSaving={isSaving}
                />
              </Stack>
            </form>
          </Paper>
        </>
      )}
    </>
  );
}

export default memo(MsgDetails);
